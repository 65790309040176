'use strict';

let analyticsTracker;

const NavigationAnalytics = {};

NavigationAnalytics.init = () => {
    NavigationAnalytics.initAnalyticsTracker();
    NavigationAnalytics.onNavMenuBrandLogosImpression();
    NavigationAnalytics.onNavMenuTopBrandsImpression();
    NavigationAnalytics.onNavMenuBrandClick();
    NavigationAnalytics.initBrandsOverviewAnalytics();
};

NavigationAnalytics.initAnalyticsTracker = () => {
    analyticsTracker = window.analyticstracker();
    analyticsTracker.setHTMLSelectors({
        eventSelector: 'data-event',
        infoSelector: 'data-analytics',
        extendPageInfo: ['url', 'userAgent', 'platform', 'domain', 'referrer']
    });
};

NavigationAnalytics.createEventData = (eventName, infoName, sectionName, content, linkName, position, targetUrl) => {
    return {
        event: eventName,
        info: {
            name: infoName,
            section_name: sectionName,
            content: content,
            link_name: linkName,
            position: position,
            target_url: targetUrl
        }
    };
};

NavigationAnalytics.onNavMenuBrandLogosImpression = () => {
    let count = 0;
    $(document).on('brandLogosNavMenu:impression', (e, data) => {
        if (count > 0) return;

        const eventData = NavigationAnalytics.createEventData(
            'navigation-impression',
            'main-menu',
            'merken-logos',
            data.brands.join('|'),
            '',
            '',
            ''
        );
        analyticsTracker.trackEvent(eventData);
        count++;
    });
};

NavigationAnalytics.onNavMenuTopBrandsImpression = () => {
    let count = 0;
    $(document).on('topBrandsNavMenu:impression', () => {
        if (count > 0) return;

        const eventData = NavigationAnalytics.createEventData(
            'navigation-impression',
            'main-menu',
            'merken-topmerken',
            '',
            '',
            '',
            ''
        );
        analyticsTracker.trackEvent(eventData);
        count++;
    });
};

NavigationAnalytics.onNavMenuBrandClick = () => {
    $('.main-nav-item[data-category-id="Merken"] .mega-nav-section-button, .main-nav-item[data-category-id="Merken"] .mega-nav-main-button, #nav-menu-mobile-nav-content-Merken .nav-menu-mobile__nav-item__image, #nav-menu-mobile-nav-content-Topmerken .nav-menu-mobile__nav-item:not(.js-nav-menu-back)').click(e => {
        e.preventDefault();
        const $currenTarget = $(e.currentTarget);
        const targetUrl = $currenTarget.prop('href');
        const position = $currenTarget.data('position') || '';
        const linkName = $currenTarget.data('link-name');
        const sectionName = $currenTarget.parents('.mega-nav-section--images').length > 0 || $currenTarget.parents('.nav-menu-mobile__nav-item--images').length > 0 ? 'merken-logos' : 'merken-topmerken';
        const eventData = NavigationAnalytics.createEventData(
            'navigation-click',
            'main-menu',
            sectionName,
            '',
            linkName,
            position,
            targetUrl
        );
        analyticsTracker.trackEvent(eventData);
        window.location.href = targetUrl;
    });
};

NavigationAnalytics.initBrandsOverviewAnalytics = () => {
    const $brandPageBanner = $('.brand-page .brand-page__banner');
    if (!$brandPageBanner.length) return;

    NavigationAnalytics.onBrandsOverviewBrandImagesImpression($brandPageBanner);
    NavigationAnalytics.onBrandsOverviewBrandImageClick($brandPageBanner);
};

NavigationAnalytics.getBrandsOverviewVisibleBrandImages = ($brandPageBanner) => {
    const brands = [];

    const containerLeft = $brandPageBanner.offset().left;
    const containerRight = containerLeft + $brandPageBanner.outerWidth();

    $brandPageBanner.find('img').each((i, el) => {
        const $image = $(el);
        const imageLeft = $image.offset().left;
        const imageRight = imageLeft + $image.outerWidth();

        // Image is partially visible or fully visible on the screen
        if ((imageRight >= containerLeft && imageLeft <= containerRight)
            || (imageLeft <= containerRight && imageRight >= containerLeft)) {
            const brandName = $image.parent('a').data('brand-name');
            brands.push(brandName);
        }
    });

    return brands;
};

NavigationAnalytics.onBrandsOverviewBrandImagesImpression = ($brandPageBanner) => {
    const brandsShown = NavigationAnalytics.getBrandsOverviewVisibleBrandImages($brandPageBanner);
    const eventData = NavigationAnalytics.createEventData(
        'navigation-impression',
        'merken',
        'merken-logos',
        brandsShown.join('|'),
        '',
        '',
        ''
    );
    analyticsTracker.trackEvent(eventData);

    let hasScrolled = false;
    $brandPageBanner.on('scroll', () => {
        hasScrolled = true;
    });
    setInterval(() => {
        if (!hasScrolled) return;

        hasScrolled = false;
        let brandsNotTrackedYet = [];
        const visibleBrandsAfterScroll = NavigationAnalytics.getBrandsOverviewVisibleBrandImages($brandPageBanner);
        visibleBrandsAfterScroll.forEach(visibleBrand => {
            if (!brandsShown.includes(visibleBrand)) {
                brandsShown.push(visibleBrand);
                brandsNotTrackedYet.push(visibleBrand);
            }
        });
        if (brandsNotTrackedYet.length > 0) {
            eventData.info.content = brandsNotTrackedYet.join('|');
            analyticsTracker.trackEvent(eventData);
        }
    }, 250);
};

NavigationAnalytics.onBrandsOverviewBrandImageClick = ($brandPageBanner) => {
    $brandPageBanner.on('click', 'a', (e) => {
        e.preventDefault();
        const $currenTarget = $(e.currentTarget);
        const targetUrl = $currenTarget.prop('href');
        const linkName = $currenTarget.data('brand-name');
        const position = $currenTarget.data('position');
        const eventData = NavigationAnalytics.createEventData(
            'navigation-click',
            'merken',
            'merken-logos',
            '',
            linkName,
            position,
            targetUrl
        );
        analyticsTracker.trackEvent(eventData);
        window.location.href = targetUrl;
    });
};

$(() => {
    NavigationAnalytics.init();
});
